import React, {FC, useEffect, useRef, useState} from 'react';
import * as Yup from "yup";
import {Link, useLocation, useNavigate} from 'react-router-dom'
import styles from "./styles.module.scss";
import Header from "../../component/Header/header";
import {register} from "../../services/services";
import {useMutation} from "react-query";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {toast} from "react-toastify";
import handleErrorMessage from "../../helper/handleErrorMessage";
import LoginFacebook from "../../component/LoginFacebook";
import LoginGoogle from "../../component/LoginGoogle";
import {useCookies} from "react-cookie";
import getRootDomain from "../../helper/domainHelper";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import useAuth from "../../hooks/useAuth";
import Preloader from "../../component/Preloader";


export interface UserSubmitForm {
  name: string,
  email: string,
  password?: string,
  password_confirmation?: string,
  accept_terms: boolean,
  social_origin?: string,
  access_token?: string,
  sub?: string,
  token?: string | null
}

const initialValues = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  accept_terms: false
};

const Register: FC = () => {

  document.title = 'Cadastro';

  const {search} = useLocation();
  const isAuthenticated = useAuth();
  const next = new URLSearchParams(search).get('next');

  const {executeRecaptcha} = useGoogleReCaptcha();

  const navigate = useNavigate()
  const acceptTermsRef = useRef<HTMLInputElement>();
  const [cookies, setCookie] = useCookies(["jwt_token", "frontendname"]);
  const [loading, setLoading] = useState(true);

  const {mutate, isLoading} = useMutation((data: UserSubmitForm) =>
    register(data), {
    onSuccess: (response) => {
      let data = response.data;
      if (data && data.token) {
        localStorage.setItem('jwt_token', data.token);
        localStorage.setItem('auth_avatar', data.user.url_avatar);
        setCookie("jwt_token", data.token, {
          domain: getRootDomain(),
          path: "/",
          maxAge: 3600 * 24 * (process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS ? parseInt(process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS) : 30)
        });
        setCookie("frontendname", data.user.name.split(" ")[0], {
          domain: getRootDomain(),
          path: "/",
          maxAge: 3600 * 24 * (process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS ? parseInt(process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS) : 30)
        });
        toast.success("Registrado com sucesso.");

        if (next) {
          window.location.href = next.includes("http://") || next.includes("https://") ? next : '/' + next;
        }

        navigate('/completar');
      } else {
        handleErrorMessage({status: response.status, data: {errors: data.errors}});
      }
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      handleErrorMessage(error.response);
    }
  });

  const validationSchema = Yup.object({
    name: Yup.string().required('Preencha com seu nome para continuar'),
    email: Yup.string().email('Email inválido').required('Preencha com seu email para continuar'),
    password: Yup.string().required('Senha inválida').min(6, 'Senha inválida').max(25, 'Senha inválida'),
    password_confirmation: Yup.string().required('Confirme sua senha para prosseguir').oneOf([Yup.ref('password'), null], 'Confirme sua senha'),
    accept_terms: Yup.boolean().oneOf([true], 'Aceite os termos para prosseguir')
  });

  const renderError = (message: any) => <p className={styles.error}>{message}</p>;

  const onSubmit = async (data: UserSubmitForm) => {
    let token = null;
    if (executeRecaptcha) {
      token = await executeRecaptcha('register');
    }
    mutate({...data, token: token})
  };

  useEffect(() => {

    if (isAuthenticated && next) {
      window.location.href = next.includes("http://") || next.includes("https://") ? next : '/' + next;
    } else {
      setLoading(false);
    }

  }, [isAuthenticated, next]);


  return (
    <>
      <Preloader isVisible={loading || isLoading}/>
      <Header/>
      <div className={styles.register}>
        <div className={styles.resize}>
          <h1>Criar conta e continuar</h1>
          <span>Usando minhas redes sociais</span>
          <div className={styles.socials}>
            <LoginFacebook
              onClickBefore={() => {
                if (!acceptTermsRef.current?.checked) {
                  toast.error("Por favor, aceite os termos")
                }
                return acceptTermsRef.current?.checked;
              }}
              callback={(name: string, email: string, access_token: string, sub: string) =>
                onSubmit({
                  name: name,
                  email: email,
                  access_token: access_token,
                  accept_terms: true,
                  social_origin: 'facebook',
                  sub: sub
                })
              }/>
            <LoginGoogle onClickBefore={() => {
              if (!acceptTermsRef.current?.checked) {
                toast.error("Por favor, aceite os termos")
              }
              return acceptTermsRef.current?.checked;
            }}
                         callback={(name: string, email: string, access_token: string, sub: string) =>
                           onSubmit({
                             name: name,
                             email: email,
                             access_token: access_token,
                             accept_terms: true,
                             social_origin: 'google',
                             sub: sub
                           })
                         }/>
          </div>
          <span>Ou usando meus dados pessoais</span>
        </div>
        <div className={styles.content}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
          >
            <Form>
              <div className={styles.boxLogin}>
                <p>Nome</p>
                <Field
                  name="name"
                  type="text"
                  placeholder="Informe o seu nome completo"
                />
                <ErrorMessage name="name" render={renderError}/>
                <p>E-mail</p>
                <Field
                  name="email"
                  placeholder="Informe seu e-mail"
                  type="text"
                  onInput={(e: { target: { value: string; }; }) => {
                    e.target.value = e.target.value.replace(/[^a-zA-Z0-9.@_-]+/g, '').toLowerCase();
                  }}
                />
                <ErrorMessage name="email" render={renderError}/>
                <p>Senha</p>
                <Field
                  name="password"
                  type="password"
                  placeholder="Digite uma senha segura"
                />
                <ErrorMessage className={styles.error} name="password" render={renderError}/>
                <p>Confirmar senha</p>
                <Field
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirmar senha"
                />
                <ErrorMessage className={styles.error} name="password_confirmation"
                              render={renderError}/>

                <label className={styles.customCheckbox}>
                  <Field
                    id={"acceptTerms"}
                    name="accept_terms"
                    className={styles.checkbox}
                    type="checkbox"
                    innerRef={acceptTermsRef}
                  />
                  <span className={styles.checkmark}></span>
                  <span>Li e aceito a <a rel="nofollow"
                                         href={process.env.REACT_APP_PRIVACY_POLICY}
                                         target="_blank">política de Privacidade</a> e
                  <a rel="nofollow"
                     href={process.env.REACT_APP_USE_TERM}
                     target="_blank"> termos de uso.</a>
                  </span>
                </label>
                <ErrorMessage className={styles.error} name="accept_terms" render={renderError}/>

                <div className={styles.formGroup}>
                  <button type="submit" disabled={isLoading}>Criar conta</button>
                </div>
              </div>
            </Form>
          </Formik>
          <div className={styles.outsideFormGroup}>
            <Link to={next ? "/entrar?next=" + encodeURIComponent(next) : "/entrar"} type="button">Já tenho conta</Link>
          </div>
        </div>
      </div>
    </>
  )
}

const TheRegister = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""}>
      <Register/>
    </GoogleReCaptchaProvider>
  )
}

export default TheRegister

