/* eslint-disable react/jsx-props-no-spreading */
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {cpf} from 'cpf-cnpj-validator';

import * as Yup from 'yup';

import arrow from '../../assets/icons/arrow.svg';
import {getCart, getCustomer, saveCustomer} from '../../services/services';

import styles from './styles.module.scss';
import {Customer} from '../../ts/interfaces/Customer';
import {Auth} from '../../ts/interfaces/Auth';
import Preloader from '../../component/Preloader';
import hasEvent from "../../helper/dataLayer";
import TagManager from "react-gtm-module";
import {Product} from "../../ts/interfaces/Product";

function formatPhone(phoneNumber: string) {
  return phoneNumber
    .replace(/\D/g, '')
    .replace(/^(\d{2})\B/, '($1) ')
    .replace(/(\d{1})?(\d{4})(\d{4})/, '$1$2-$3');
}

function formatCPF(cpf: string) {
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

function formatCEP(cep: string) {
  return cep
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d{3})/, '$1-$2');
}

export default function Complete() {

  document.title = 'Complete seus dados pessoais';

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [isOnlyProductDigital, setIsOnlyProductDigital] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Preencha com seu nome completo')
      .test('is-valid-name', (item, schema) => {
        if (!item) {
          return schema.createError({
            message: 'Preencha seu nome completo',
            path: 'name',
          });
        }

        if (item.trim().split(" ").length <= 1) {
          return schema.createError({
            message: 'Preencha com Nome e Sobrenome',
            path: 'name',
          });
        }

        return true;
      }),
    email: Yup.string()
      .required('Preencha com seu email para continuar')
      .email('Email inválido'),
    cpf: Yup.string()
      .required('Preencha seu CPF')
      .min(14, 'Preencha um CPF válido')
      //@ts-ignore
      .test('is-valid-cpf', (item, schema) => {
        if (!item) {
          return schema.createError({
            message: 'Preencha seu CPF',
            path: 'cpf',
          });
        }

        if (!cpf.isValid(item)) {
          return schema.createError({
            message: 'CPF preenchido é inválido',
            path: 'cpf',
          });
        }

        return true;
      }),

    phone: Yup.string()
      .required('Preencha seu celular')
      .min(15, 'Preencha um número válido'),
    zipcode: Yup.string()
      .required('Preencha seu cep')
      .min(9, 'Preencha um cep válido'),
    address: Yup.string()
      .required('Preencha com seu endereço'),
    number: Yup.string()
      .required('Preencha seu número'),
    district: Yup.string()
      .required('Preencha seu bairro'),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    watch,
    formState: {errors},
  } = useForm<Customer>({
    resolver: yupResolver(validationSchema),
  });

  const watchZipcode = watch("zipcode", "");

  useEffect(() => {
    if (!showAddress && watchZipcode && watchZipcode.length === 9) {
      setShowAddress(true);
    }
  }, [watchZipcode, setShowAddress]);

  function handlePhone(e: React.ChangeEvent<HTMLInputElement>) {
    const formatedValue = formatPhone(e.target.value);
    setValue('phone', formatedValue);
  }

  function handleCPF(e: React.ChangeEvent<HTMLInputElement>) {
    const formatedValue = formatCPF(e.target.value);
    setValue('cpf', formatedValue);
  }

  async function onSubmit(data: Customer) {
    setLoading(true);

    try {
      if (!data) {
        return;
      }

      const customer = {
        name: data.name,
        cpf: data.cpf,
        phone: data.phone,
        zipcode: data.zipcode,
        address: data.address,
        number: data.number,
        district: data.district,
        city: data.city,
        state: data.state,
        complement: data.complement,
        reference: data.reference,
      };

      const response = await saveCustomer(customer);
      if (response.data.success) {
        if (isOnlyProductDigital) {
          navigate('/pagamento');
        } else {
          navigate('/frete');
        }
      }
    } catch (error) {
      console.error('ERROR::ON_SUBMIT', error);
    } finally {
      setLoading(false);
    }
  }

  async function searchCEP(cep: string) {
    const replacedCep = cep.replace('-', '');

    if (replacedCep.length !== 8) {
      return;
    }
    setLoading(true);

    fetch(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
      .then((res) => res.json()).then((data) => {
      if (data.erro) {
        setError('zipcode', {message: 'CEP não encontrado'});
        return;
      }

      clearErrors('zipcode');
      setValue('state', data.uf);
      setValue('city', data.localidade);
      setValue('district', data.bairro);
      setValue('address', data.logradouro);
    }).finally(() => {
      setLoading(false);
    });
  }

  function handleCEP(e: React.ChangeEvent<HTMLInputElement>) {
    const cep = formatCEP(e.target.value);
    setValue('zipcode', formatCEP(cep));
    searchCEP(cep);
  }

  const setDefaultValues = useCallback(async (data: Auth, customer: Customer) => {
    const defaultValues = {
      email: data.email,
      name: data.name,
    };

    const customerValues = {
      name: customer.name ? customer.name : "",
      cpf: customer.cpf ? customer.cpf : "",
      phone: customer.phone ? customer.phone : "",
      zipcode: customer.zipcode ? customer.zipcode : "",
      address: customer.address ? customer.address : "",
      number: customer.number ? customer.number : "",
      district: customer.district ? customer.district : "",
      city: customer.city ? customer.city : "",
      state: customer.state ? customer.state : "",
      complement: customer.complement ? customer.complement : "",
      reference: customer.reference ? customer.reference : "",
    };

    if (customer) {
      const newDefaultValues = {
        ...defaultValues,
        ...customerValues,
      };

      reset(newDefaultValues);
    } else {
      reset(defaultValues);
    }
  }, [reset]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const {data} = await getCustomer();
        if (data.success) {
          const res = data.data[0];
          let params = '?hiddenBump=false'

          if (new URLSearchParams(location.search).get('hiddenBump') === 'true') {
            params = '?hiddenBump=true'
          }

          if (location.state === "cart" &&
            res.customer.cpf &&
            res.customer.zipcode) {
            navigate('/pagamento' + params)
          }
          if (location.state === "cartDigital" &&
            res.customer.cpf &&
            res.customer.zipcode) {
            navigate('/pagamento' + params)
          }

          setDefaultValues(res, Object.assign(res.customer, {name: res.name}));
        }
      } catch (error) {
        console.error('ERROR::GET_CUSTOMER', error)
      } finally {
        setLoading(false);
      }
    })();
  }, [setDefaultValues]);

  const getCartCallback = useCallback(async () => {
    try {

      const {data} = await getCart();

      if (data.success && data.data) {
        const cart = data.data;

        setIsOnlyProductDigital(cart.items.every((item: Product) => !item.is_shipping));

        if (!hasEvent('begin_checkout')) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'begin_checkout',
              ecommerce: {
                items: cart.items.map((item: Product) => {
                  return {
                    item_brand: "Nova Concursos",
                    item_category: item.type + "/" + item.name,
                    item_id: item.sku,
                    item_name: item.name,
                    price: item.price - item.discount,
                    quantity: Number.parseInt(item.qty),
                    item_variant: item.type
                  }
                })
              },
              em: cart.customer_email_hash256,
              ph: cart.customer_ph_hash256,
              fn: cart.customer_fn_hash256,
              ln: cart.customer_ln_hash256,
              customer_email: cart.customer_email,
              customer_ph: cart.customer_ph,
              customer_fn: cart.customer_fn,
              customer_ln: cart.customer_ln
            }
          });
        }
      }
    } catch (error) {
      console.log('complete get cart::ERROR', error)
    }
  }, []);


  useEffect(() => {
    getCartCallback()
  }, [getCartCallback]);

  useEffect(() => {
    console.log('isOnlyProductDigital', isOnlyProductDigital);
  }, [isOnlyProductDigital])

  return (
    <>
      <Preloader isVisible={isLoading}/>
      {isLoading ? "" : <>
        <div className={styles.register}>
          <h1>Complete seus dados pessoais</h1>
        </div>
        <div className={styles.content}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.boxLogin}>
              <div className={styles.align}>
                <p>Nome Completo</p>
                <input
                  id="name"
                  maxLength={60}
                  {...register('name')}
                />
                <span className={styles.error}>{errors.name?.message}</span>

                <p>Informe seu CPF</p>
                <input
                  id="cpf"
                  placeholder="000.000.000-00"
                  maxLength={14}
                  {...register('cpf', {
                    onChange: handleCPF,
                  })}
                />
                <span className={styles.error}>{errors.cpf?.message}</span>

                <p>Informe seu Celular</p>
                <input
                  id="phone"
                  placeholder="(XX) - 9XXXX-XXXX"
                  maxLength={15}
                  {...register('phone', {
                    onChange: handlePhone,
                  })}
                />
                <span className={styles.error}>{errors.phone?.message}</span>

                <p>CEP</p>
                <div className={styles.cep}>
                  <input
                    id="zipcode"
                    maxLength={9}
                    autoComplete="chrome-off"
                    {...register('zipcode', {
                      onChange: handleCEP,
                    })}
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php/"
                  >
                    Não sei o cep
                  </a>
                </div>
                <span className={styles.error}>{errors.zipcode?.message}</span>
                <div className={styles.address + " " + (showAddress ? styles.show : "")}>
                  <div className={styles.group}>
                    <div>
                      <p>UF</p>
                      <input
                        readOnly
                        type="text"
                        className={styles.number}
                        {...register('state')}
                      />
                    </div>
                    <div>
                      <p>Cidade</p>
                      <input
                        id="city"
                        readOnly
                        className={styles.complete}
                        maxLength={60}
                        {...register('city')}
                      />
                    </div>
                  </div>

                  <p>Endereço</p>
                  <input
                    id="address"
                    maxLength={60}
                    {...register('address')}
                  />
                  <span className={styles.error}>{errors.address?.message}</span>

                  <div className={styles.group}>
                    <div>
                      <p>Número</p>
                      <input
                        id="number"
                        className={styles.number}
                        maxLength={5}
                        {...register('number')}
                      />
                      <span className={styles.error}>{errors.number?.message}</span>
                    </div>
                    <div>
                      <p>Complemento</p>
                      <input
                        type="text"
                        className={styles.complete}
                        {...register('complement')}
                      />
                    </div>
                  </div>

                  <p>Bairro</p>
                  <input
                    id="district"
                    maxLength={30}
                    {...register('district')}
                  />
                  <span className={styles.error}>
                    {errors.district?.message}
                  </span>

                  <p>Referência</p>
                  <input
                    id="reference"
                    type="text"
                    {...register('reference')}
                  />
                </div>
              </div>
              <div className={styles.formGroup}>
                <button type="submit">
                  Ir para Envio
                  <img src={arrow} alt="arrow"/>
                </button>
              </div>
            </div>
          </form>
        </div>
      </>}
    </>
  );
}
