import React, {FC, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import styles from "./styles.module.scss";
import Header from "../../component/Header/header";
import google from "../../assets/icons/google.png";
import eye from "../../../src/assets/icons/eye.svg";
import ModalRemeberPassword from "../../component/ModalRemeberPassword/ModalRemeberPassword";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {useMutation} from "react-query";
import {login} from "../../services/services";
import {toast} from "react-toastify";
import handleErrorMessage from "../../helper/handleErrorMessage";
import {useCookies} from "react-cookie";
import LoginFacebook from "../../component/LoginFacebook";
import LoginGoogle from "../../component/LoginGoogle";
import getRootDomain from "../../helper/domainHelper";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import getCookie from "../../helper/getCookie";
import useAuth from "../../hooks/useAuth";
import Preloader from "../../component/Preloader";

export interface UserLoginForm {
  email: string,
  password?: string,
  social_origin?: string,
  access_token?: string,
  sub?: string,
  token?: string | null
}

const Login: FC = () => {

  document.title = 'Login';

  const {search} = useLocation();
  const isAuthenticated = useAuth();
  const next = new URLSearchParams(search).get('next');

  const {executeRecaptcha} = useGoogleReCaptcha();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [cookies, setCookie] = useCookies(["jwt_token", "frontendname", "auth_avatar"]);
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Preencha com seu email para continuar'),
    password: Yup.string().required('Senha inválida').min(1, 'Senha inválida').max(25, 'Senha inválida'),
  });

  const initialValues = {
    email: "",
    password: ""
  };

  const {mutate, isLoading} = useMutation((data: UserLoginForm) =>
    login(data), {
    onSuccess: (response) => {
      let data = response.data;
      if (data && data.token) {
        localStorage.setItem('jwt_token', data.token);
        localStorage.setItem('auth_avatar', data.user.url_avatar);
        setCookie("jwt_token", data.token, {
          domain: getRootDomain(),
          path: "/",
          maxAge: 3600 * 24 * (process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS ? parseInt(process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS) : 30)
        });
        setCookie("auth_avatar", encodeURIComponent(data.user.url_avatar), {
          domain: getRootDomain(),
          path: "/",
          maxAge: 3600 * 24 * (process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS ? parseInt(process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS) : 30)
        });
        setCookie("frontendname", data.user.name.split(" ")[0], {
          domain: getRootDomain(),
          path: "/",
          maxAge: 3600 * 24 * (process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS ? parseInt(process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS) : 30)
        });
        toast.success("Login com sucesso.");

        if (next) {
          window.location.href = next.includes("http://") || next.includes("https://") ? next : '/' + next;
        }

        navigate('/completar');
      } else {
        handleErrorMessage({status: response.status, data: {errors: data.errors}});
      }
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      handleErrorMessage(error.response);
    }
  });

  const renderError = (message: any) => <p className={styles.error}>{message}</p>;

  const onSubmit = async (data: UserLoginForm) => {
    let token = null;
    if (executeRecaptcha) {
      token = await executeRecaptcha('login');
    }
    mutate({...data, token: token})
  };

  useEffect(() => {

    if (isAuthenticated && next) {
      window.location.href = next.includes("http://") || next.includes("https://") ? next : '/' + next;
    } else {
      setLoading(false);
    }

  }, [isAuthenticated, next]);

  return (
    <>
      <Preloader isVisible={loading || isLoading}/>
      <Header/>
      <div className={styles.login}>
        <div className={styles.resize}>
          <h1>Acessar e continuar</h1>
          <span>Usando minhas redes sociais</span>
          <div className={styles.socials}>
            <LoginFacebook
              onClickBefore={() => true}
              callback={(name: string, email: string, access_token: string, sub: string) =>
                onSubmit({
                  email: email,
                  access_token: access_token,
                  social_origin: 'facebook',
                  sub: sub
                })
              }/>
            <LoginGoogle onClickBefore={() => true}
                         callback={(name: string, email: string, access_token: string, sub: string) =>
                           onSubmit({
                             email: email,
                             access_token: access_token,
                             social_origin: 'google',
                             sub: sub
                           })
                         }/>
          </div>
          <span>Ou usando meus dados pessoais</span>
        </div>
        <div className={styles.content}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
          >
            <Form>
              <div className={styles.boxLogin}>
                <div className={styles.inputGroup}>
                  <p>Email</p>
                  <Field
                    name="email"
                    placeholder="Informe seu e-mail"
                    type="text"
                    onInput={(e: { target: { value: string; }; }) => {
                      e.target.value = e.target.value.replace(/\s/g, '');
                    }}
                  />
                  <ErrorMessage className={styles.error} name="email" render={renderError}/>
                </div>
                <div className={styles.inputGroup}>
                  <p>Senha</p>
                  <Field name="password"
                         type={showPassword ? "text" : "password"}
                         placeholder="Informe sua senha"
                  />
                  <img src={eye} alt="show pass" onClick={() => setShowPassword(!showPassword)}/>
                  <ErrorMessage className={styles.error} name="password" render={renderError}/>
                </div>
                <div className={styles.formGroup}>
                  <button disabled={isLoading} type="submit">Entrar</button>
                </div>
                <div className={styles.group}>
                  <button type={"button"} className={styles.remember} onClick={() => setShowModal(!showModal)}>Esqueci
                    minha senha
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        <div className={styles.outsideFormGroup}>
          <Link to={next ? "/cadastro?next=" + encodeURIComponent(next) : "/cadastro"} type="button">Não tenho
            conta</Link>
        </div>
      </div>
      {showModal &&
        <ModalRemeberPassword
          closeModal={() => setShowModal(!showModal)}
          modalOpen={showModal}
        />
      }
    </>
  )
}

const TheLogin = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""}>
      <Login/>
    </GoogleReCaptchaProvider>
  )
}

export default TheLogin

