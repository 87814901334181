import { QueryClientProvider } from "react-query";
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';


import './index.scss';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import {queryClient} from "./services/queryClient";

TagManager.initialize({
  gtmId: process.env.REACT_APP_TAGMANAGER ? process.env.REACT_APP_TAGMANAGER : ""
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
