import React, {useState, useContext, useEffect, useCallback, useRef} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
//@ts-ignore
import pagarme from 'pagarme/browser';
import {set, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import TagManager from "react-gtm-module";

import back from "../../assets/icons/arrowPoint.svg";
import arrowBottom from "../../assets/icons/arrowBottom.svg";

import Preloader from '../../component/Preloader';

import {CartContext} from '../../context/CartContext';
import {AddressContext} from '../../context/AddressContext';

import {
  getCart,
  getAddressToSendProduct,
  createPaymentMode,
  applyCouponCart,
  selectPaymentMethodCart, applyCashbackCart
} from '../../services/services';

import {Cart} from '../../ts/interfaces/Cart';
import {Address} from '../../ts/interfaces/Address';
import {formatMoneyMask, formatFloatToDecimal, formatMask, toBoolean} from '../../helper/FormatFields';

import styles from "./styles.module.scss";
import {useMutation} from "react-query";
import handleErrorMessage from "../../helper/handleErrorMessage";
import getInstallmentValue from "../../helper/InstallmentHelper";
import OrderBump from "./OrderBump";
import hasEvent from "../../helper/dataLayer";

import PagarmeCC from "./PaymentOptions/PagarmeCC";
import PagarmePix from "./PaymentOptions/PagarmePix";
import PagarmeBoleto from "./PaymentOptions/PagarmeBoleto";
import CustomPayment from "./PaymentOptions/CustomPayment";
import PagalevePixParcelado from "./PaymentOptions/PagalevePixParcelado";

import useSessionStorage from "../../hooks/useSessionStorage";
import EnumPaymentMethodParameter from "../../enum/EnumPaymentMethodParameter";

import thunder from "../../assets/icons/thunder.svg";
import Timer from "../../component/Timer/timer";
import ModalPagaleve from "./ModalPagaleve";
import DiscountLightning from "../DiscountLightning/DiscountLightning";
import MercadoPagoCC from "./PaymentOptions/MercadoPagoCC";
import {initMercadoPago, getPaymentMethods} from '@mercadopago/sdk-react';
import {createCardToken} from '@mercadopago/sdk-react/coreMethods';
import MercadoPagoPix from "./PaymentOptions/MercadoPagoPix";
import MercadoPagoBoleto from "./PaymentOptions/MercadoPagoBoleto";

initMercadoPago(process.env.REACT_APP_MERCADOPAGO_PUB_KEY ?? "TEST-53cd1322-5b0d-405c-9f17-0c9aef611d00");


interface Card {
  card_number: string,
  card_expiration_date: string,
  card_cvv: string,
  card_holder_name: string,
}

interface Schema extends Card {
  installments: number;
}

export default function Payment() {
  document.title = 'Pagamento';
  const [showPixParcelado, setShowPixParcelado] = useSessionStorage(
    '@pix-parcelado', ''
  );

  const {isOnlyDigitalProduct, onChangeCart} = useContext(CartContext);
  const {setAddress: onChangeAddress} = useContext(AddressContext);

  const navigate = useNavigate();
  const {search} = useLocation();
  const formRef = useRef<any>()

  const [option, setOption] = useState('');
  const [cupom, setCupom] = useState("");
  const [openCupom, setOpenCupom] = useState(false);
  const [installments, setInstallments] = useState<number[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [cart, setCart] = useState({} as Cart);
  const [address, setAddress] = useState({} as Address);
  const [payment, setPayment] = useState<string[]>([]);
  const [openBoleto, setOpenBoleto] = useState(false);
  const [checkoutDiscount, setCheckoutDiscount] = useState(false);
  const [expiredCheckoutDiscount, setExpiredCheckoutDiscount] = useState(false);
  const [percentageCheckoutDiscount, setPercentageCheckoutDiscount] = useState(0);
  const [openModalPagaleve, setOpenModalpagaleve] = useState(false);
  const [urlIframePagaleve, setUrlIframePavaleve] = useState('');
  const [showPagarmeCC,] = useState(toBoolean(process.env.REACT_APP_SHOW_PAGARME_CC, true));
  const [showMercadoPagoCC, setShowMercadoPagoCC] = useState(toBoolean(process.env.REACT_APP_SHOW_MERCADOPAGO_CC, true));
  const [showPagarmePIX,] = useState(toBoolean(process.env.REACT_APP_SHOW_PAGARME_PIX, true));
  const [showMercadoPagoPIX, setShowMercadoPagoPix] = useState(toBoolean(process.env.REACT_APP_SHOW_MERCADOPAGO_PIX, true));
  const [showPagarmeBoleto] = useState(toBoolean(process.env.REACT_APP_SHOW_PAGARME_BOLETO, true));
  const [showMercadoPagoBoleto, setShowMercadoPagoBoleto] = useState(toBoolean(process.env.REACT_APP_SHOW_MERCADOPAGO_BOLETO, true));
  const hiddenBump = new URLSearchParams(useLocation().search).get('hiddenBump') === 'true';

  const validationSchema = Yup.object().shape({
    card_holder_name: Yup.string().required('Preencha o nome'),
    card_number: Yup.string().required('Preencha o número do cartão'),
    card_cvv: Yup.string().required('Preencha o código segurança'),
    card_expiration_date: Yup.string().required('Preencha a validade do cartão')
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Schema>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {

    let params = new URLSearchParams(search);
    if (params.get('mercadopago')) {
      setShowMercadoPagoPix(true);
      setShowMercadoPagoCC(true);
      setShowMercadoPagoBoleto(true);
    }

    setShowPixParcelado(process.env.REACT_APP_SHOW_PIX_PARCELADO ?? 'false');

    let newOption = showMercadoPagoCC ? EnumPaymentMethodParameter.xmpc : EnumPaymentMethodParameter.xpc;

    if (sessionStorage.getItem('payment_method') !== '') {
      let arrayPayment: string[] = [];
      let paymentMethod = sessionStorage.getItem('payment_method')?.split(',');
      paymentMethod?.map((item) => {
        // @ts-ignore
        arrayPayment.push(EnumPaymentMethodParameter[item]);
      });

      setPayment(arrayPayment);

      if (arrayPayment.includes(EnumPaymentMethodParameter.xpc) &&
        arrayPayment.includes(EnumPaymentMethodParameter.xpp) &&
        arrayPayment.includes(EnumPaymentMethodParameter.xpb)) // as 3 formas selecionadas para não serem exibidas
        setPayment([]);

      if (arrayPayment.includes(newOption))
        newOption = EnumPaymentMethodParameter.xpp;

      if (arrayPayment.includes(EnumPaymentMethodParameter.xsb))
        setOpenBoleto(true);

      if (arrayPayment.includes(EnumPaymentMethodParameter.xspp))
        newOption = 'pagaleve_pix_parcelado';

      if (arrayPayment.includes(EnumPaymentMethodParameter.xcp))
        newOption = EnumPaymentMethodParameter.xcp;
    }

    setOption(newOption);

  }, []);

  useEffect(() => {

    if (cart === null || (cart.items && cart.items.length === 0)) {
      navigate('/');
    }

    if (cart && Object.keys(cart).length > 0) {

      setCupom(cart.coupon ? cart.coupon : "");
      if (cart && Number(cart.total_value) === 0) {
        setOption('custompayment');
      } else if (cart.total_value < 1) {
        setOption('pagarme_pix');
      } else if (cart.payment_method && option === '') {
        setOption(cart.payment_method);
      }


      if (!hasEvent('begin_checkout')) {
        TagManager.dataLayer({
          dataLayer: {
            event: "begin_checkout",
            ecommerce: {
              items: cart.items.map((item) => {
                return {
                  item_brand: "Nova Concursos",
                  item_category: item.type + "/" + item.name,
                  item_id: item.sku,
                  item_name: item.name,
                  price: item.price - item.discount,
                  quantity: Number.parseInt(item.qty),
                  item_variant: item.type
                }
              })
            }
          }
        })
      }

      if (!hasEvent('ee_checkout')) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'ee_checkout',
            email: cart.customer_email,
            externalCheckoutId: cart.session_id,
            name: cart.customer_name,
            totalValue: cart.total_value,
            em: cart.customer_email_hash256,
            ph: cart.customer_ph_hash256,
            fn: cart.customer_fn_hash256,
            ln: cart.customer_ln_hash256,
            customer_email: cart.customer_email,
            customer_ph: cart.customer_ph,
            customer_fn: cart.customer_fn,
            customer_ln: cart.customer_ln,
            ecommerce: {
              checkout: {
                actionField: {step: 1},
                products: cart.items.map((item) => {
                  return {
                    brand: "Nova Concursos",
                    category: item.type + "/" + item.name,
                    id: item.sku,
                    image: item.path_image,
                    name: item.name,
                    price: item.price - item.discount,
                    quantity: item.qty,
                    variant: item.type
                  }
                })
              }
            },
            product_ids: cart.items.map((item) => {
              return item.sku
            })
          }
        });
      }

      if (cart.discount_amount_checkout && Number(cart.discount_amount_checkout) > 0) {
        setCheckoutDiscount(true);

        let shippingDiscount = 0;

        if (cart.shipping && cart.shipping.id && cart.shipping.price) {
          shippingDiscount = cart.shipping.price;
          if (cart.shipping.discount)
            shippingDiscount = cart.shipping.price - cart.shipping.discount
        }

        let total = cart.total_value - shippingDiscount + parseFloat(cart.discount_amount_checkout);
        console.log(total);
        let porcentagem = Math.round((parseFloat(cart.discount_amount_checkout) * 100) / total);

        setPercentageCheckoutDiscount(porcentagem);
      }
    }

  }, [cart]);

  useEffect(() => {
    if (cart && cart.total_value) {
      let maxPermited = getInstallmentValue(cart.total_value);

      if (option === EnumPaymentMethodParameter.xpc) {
        let body = {
          interest_rate: 0,
          amount: Math.round(Number(cart.total_value) * 100),
          max_installments: maxPermited,
          free_installments: maxPermited
        };
        let response = pagarme.client.connect({encryption_key: process.env.REACT_APP_PAGARME_KEY})
          .then((client: any) => client.transactions.calculateInstallmentsAmount(body))
          .catch((error: any) => {
            console.error('PAGARME::INSTALLMENT', error);
            toast.error('Ocorreu um erro inesperado, nossos analistas foram notificados, tente mais tarde');
          });

        response.then((result: any) => {
          let amounts = Object.keys(result.installments).map((key) => {
            return result.installments[key].installment_amount / 100;
          })
          setInstallments(amounts);
        })
      } else if (option === EnumPaymentMethodParameter.xmpc) {
        let amounts = []
        for (let i = 1; i <= maxPermited; i++) {
          amounts.push(Math.ceil(cart.total_value / i * 100) / 100)
        }
        setInstallments(amounts)
      }
    }
  }, [cart, option]);

  useEffect(() => {
    if (urlIframePagaleve !== '') {
      setOpenModalpagaleve(true);
    }
  }, [urlIframePagaleve])

  function handleNavigateBack() {
    if (isOnlyDigitalProduct) {
      navigate('/completar');
    } else {
      navigate('/frete');
    }
  }

  function handleChangeAddress() {
    onChangeAddress(address);
    navigate('/enderecos');
  }

  async function getCardPaymentInformations(card: Card) {
    const response = pagarme.client.connect({encryption_key: process.env.REACT_APP_PAGARME_KEY})
      .then((client: any) => client.security.encrypt(card))
      .catch((error: any) => {
        console.error('PAGARME::ERROR', error);
        toast.error('Ocorreu um erro inesperado, nossos analistas foram notificados, tente mais tarde.');
      });


    return response;
  }

  async function getCardPaymentInformationsMercadoPago(card: Card) {
    let paymentMethods = await getPaymentMethods({bin: card.card_number.replaceAll(".", "").replaceAll("_", "")})
    let paymentMethodId = ''
    let issuer = 0
    if (paymentMethods && paymentMethods.results.length > 0) {
      paymentMethodId = paymentMethods.results[0].id
      issuer = paymentMethods.results[0].issuer.id
    }

    if (!cart.customer_document) {
      toast.error('Antes de finalizar a compra preencha o seu CPF.');
      navigate('/completar');
      return null;
    }

    let date = card.card_expiration_date.split("/");

    let month = parseInt(date[0])
    if (month <= 0 || month > 12) {
      toast.error('Preencha um mês válido.');
      return null;
    }

    let year = parseInt(date[1])
    if (year < new Date().getFullYear()) {
      toast.error('Preencha um ano válido.');
      return null;
    }

    if (year === new Date().getFullYear() && month < new Date().getMonth() + 1) {
      toast.error('Preencha uma data válida.');
      return null;
    }

    let cardToken = await createCardToken({
      cardNumber: card.card_number.replaceAll(".", "").replaceAll("_", ""),
      cardholderName: card.card_holder_name,
      cardExpirationMonth: date[0].toString(),
      cardExpirationYear: date[1].toString(),
      securityCode: card.card_cvv,
      identificationType: 'CPF',
      identificationNumber: cart.customer_document.replaceAll(".", "").replaceAll("-", ""),
    });

    return {
      token: cardToken?.id,
      paymentMethodId: paymentMethodId,
      issuer: issuer
    }
  }

  async function onSubmit(values: any) {

    setLoading(true);

    const options = {
      method: option,
      card_hash: null,
      installments: 1,
      paymentMethodId: '',
      issuer: 0
    }

    if (option === 'pagarme_cc') {
      const cardHash = await getCardPaymentInformations(values);
      if (!cardHash) {
        return;
      }
      options.card_hash = cardHash;
      options.installments = Number(values.installments);
    }

    if (option === 'mercadopago_cc') {
      const mp = await getCardPaymentInformationsMercadoPago(values);
      if (!mp) {
        setLoading(false);
        return;
      }
      // @ts-ignore
      options.card_hash = mp.token;
      options.paymentMethodId = mp.paymentMethodId;
      options.issuer = mp.issuer;
      options.installments = Number(values.installments);
    }

    if (option === 'mercadopago_pix') {
      options.paymentMethodId = 'pix';
    }

    if (option === 'mercadopago_boleto') {
      options.paymentMethodId = 'bolbradesco';
    }

    try {
      const {data} = await createPaymentMode(options);
      if (data.success) {
        if (data.url_redirect) {
          setUrlIframePavaleve(data.url_redirect);
        } else if (data.url_redirect_upsell) {
          window.location.href = data.url_redirect_upsell;
        } else {
          navigate('finalizado', {replace: true});
        }
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      toast.error('Ocorreu um erro inesperado, nossos analistas foram notificados, tente mais tarde.');
      console.log('CARD_PAYMENT:ERRO', error);
    } finally {
      setLoading(false);
    }
  }

  const {mutate: mutateCupom, isLoading: isLoadingCupom} = useMutation(async (data: { coupon: string }) => {
    return await applyCouponCart(data).catch((error) => {
      handleErrorMessage(error.response);
      return error;
    });
  }, {
    onSuccess: ({response, data}) => {

      if (data) {
        if (data.success && data.data) {
          onChangeCart(data.data)
          setCart(data.data);
          if (data.data.coupon) {
            toast.success("Cupom aplicado")
          } else {
            toast.error("Cupom inválido")
          }

        } else {
          toast.error("Cupom inválido")
        }
      } else {
        if (response) {
          handleErrorMessage({status: response.status, data: {errors: response.data.errors}});
        } else {
          toast.error("Cupom inválido.")
        }
      }
    },
    onError: (response) => {
      console.log(response)
      toast.error("Erro inesperado, tente mais tarde");
    }
  });

  const {mutate: mutateCashback, isLoading: isLoadingCashback} = useMutation(async (data: { apply: boolean }) => {
    return await applyCashbackCart(data).catch((error) => {
      handleErrorMessage(error.response);
      return error;
    });
  }, {
    onSuccess: ({response, data}) => {
      if (data) {
        if (data.success && data.data) {
          onChangeCart(data.data);
          setCart(data.data);
          if (data.data.apply_cashback) {
            toast.success("Cashback aplicado")
          } else {
            toast.success("Cashback removido")
          }

        } else {
          toast.error("Cashback inválido")
        }
      } else {
        if (response) {
          handleErrorMessage({status: response.status, data: {errors: response.data.errors}});
        } else {
          toast.error("Cashback inválido.")
        }
      }
    },
    onError: (response) => {
      console.error(response)
      toast.error("Erro inesperado, tente mais tarde");
    }
  });

  const {mutate: mutateRemoveCupom, isLoading: isLoadingRemoveCupom} = useMutation(async (data: { coupon: string }) => {
    return await applyCouponCart(data).catch((error) => {
      handleErrorMessage(error.response);
      return error;
    });
  }, {
    onSuccess: ({response, data}) => {
      if (data) {
        if (data.success && data.data) {
          onChangeCart(data.data)
          setCart(data.data);
        }
        toast.info("Cupom removido")
      }
    },
    onError: (response) => {
      console.log(response)
      toast.error("Erro inesperado, tente mais tarde");
    }
  });


  const {mutate: mutatePayment, isLoading: isLoadingPayment} = useMutation(async (data: { payment_method: string }) => {
    return await selectPaymentMethodCart(data).catch((error) => {
      handleErrorMessage(error.response);
      return error;
    });
  }, {
    onSuccess: ({response, data}) => {
      if (data) {
        if (data.success && data.data) {
          onChangeCart(data.data)
          setCart(data.data);
        } else {
          navigate('/');
        }
      } else {
        if (response) {
          handleErrorMessage({status: response.status, data: {errors: response.data.errors}});
        } else {
          toast.error("Forma Pagamento inválido.")
        }
      }
    },
    onError: (response) => {
      toast.error("Erro inesperado, tente mais tarde");
    }
  });

  async function selectPaymentMethod(option: string) {
    await mutatePayment({payment_method: option})
    setOption(option);
  }

  async function removeCupom() {
    mutateRemoveCupom({coupon: ""});
  }

  async function applyCupom() {
    mutateCupom({coupon: cupom});
  }

  async function applyCashback(apply: boolean) {
    mutateCashback({apply: apply});
  }

  const getAddress = useCallback(async () => {
    const {data} = await getAddressToSendProduct();

    if (!data.data) {
      navigate('/completar');
    }

    const result = data.data;

    try {
      if (result) {
        setAddress(result);
      }
    } catch (error) {
      console.error('GETADDRESS_PAYMENT:ERROR', error);
    }
  }, [navigate]);

  const listProducts = useCallback(async () => {
    try {
      const {data} = await getCart();

      if (data.success) {
        onChangeCart(data.data)
        setCart(data.data);
      }

      if (!data.data) {
        navigate('/');
      }

    } catch (error) {
      console.error('LISTPRODUCTS_PAYMENT::ERROR', error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    Promise.all([getAddress(), listProducts()])
      .finally(() => {
        setLoading(false);
      })
  }, [listProducts, getAddress]);

  useEffect(() => {

    if (Object.keys(cart).length > 0 && cart.shipping.cep !== null &&
      Object.keys(address).length > 0) {
      if (cart.shipping?.cep.replace("-", "") !== address.zipcode.replace("-", "")) {
        navigate('/frete');
      }
    }
  }, [cart, address]);

  const totalDiscount = Number(cart.discount_amount || 0) +
    Number(cart.discount_amount_campaign || 0);

  return (<>

      <Preloader isVisible={isLoading || isLoadingCupom || isLoadingPayment}/>
      <div className={styles.register}>
        <h1>Como você prefere pagar?</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.agroupment}>

          <span className={styles.backPage} onClick={handleNavigateBack}>
            <img src={back} alt="arrow"/>
            Voltar
          </span>

          {cart && Object.keys(cart).length > 0 && !hiddenBump ?
            <OrderBump cart={cart} setCart={(cart: Cart) => setCart(cart)} checkoutDiscount={checkoutDiscount}/> : ""}

          <div className={styles.boxPayment}>
            {cart && cart.total_value > 0 ? <>

              {!payment.includes(EnumPaymentMethodParameter.xspp) ?
                <>
                  {(payment.length === 0 || !payment.includes(EnumPaymentMethodParameter.xpc)) && cart.total_value >= 1 && showPagarmeCC ?
                    <PagarmeCC option={option}
                               selectPaymentMethod={selectPaymentMethod}
                               formRef={formRef}
                               handleSubmit={handleSubmit}
                               onSubmit={onSubmit}
                               register={register}
                               errors={errors}
                               installments={installments}
                               cart={cart}/> : ""
                  }
                  {(payment.length === 0 || !payment.includes(EnumPaymentMethodParameter.xmpc)) && cart.total_value >= 1 && showMercadoPagoCC ?
                    <MercadoPagoCC option={option}
                                   selectPaymentMethod={selectPaymentMethod}
                                   formRef={formRef}
                                   handleSubmit={handleSubmit}
                                   onSubmit={onSubmit}
                                   register={register}
                                   errors={errors}
                                   installments={installments}
                                   cart={cart}/> : ""
                  }
                </> : ""}

              {!payment.includes(EnumPaymentMethodParameter.xspp) ?
                <>
                  {((payment.length === 0 || !payment.includes(EnumPaymentMethodParameter.xpp)) && showPagarmePIX) ?
                    <PagarmePix option={option}
                                selectPaymentMethod={selectPaymentMethod}/> : ""
                  }
                  {((payment.length === 0 || !payment.includes(EnumPaymentMethodParameter.xmpp)) && showMercadoPagoPIX) ?
                    <MercadoPagoPix option={option}
                                    selectPaymentMethod={selectPaymentMethod}/> : ""
                  }
                </> : ""}

              {showPixParcelado === "true" || payment.includes(EnumPaymentMethodParameter.xspp) ?
                <PagalevePixParcelado option={option}
                                      selectPaymentMethod={selectPaymentMethod}/> : ""}

              {!payment.includes(EnumPaymentMethodParameter.xspp) ?
                <>
                  {(payment.length === 0 || !payment.includes(EnumPaymentMethodParameter.xpb)) && cart.total_value >= 1 ?
                    <>
                      {openBoleto ?
                        <>
                          {showPagarmeBoleto &&
                            <PagarmeBoleto option={option} selectPaymentMethod={selectPaymentMethod}/>
                          }
                          {showMercadoPagoBoleto &&
                            <MercadoPagoBoleto option={option} selectPaymentMethod={selectPaymentMethod}/>
                          }
                        </> : ""
                      }
                    </> : ""
                  }
                </> : ""}

            </> : ""}

            {cart && cart.total_value === 0 ? <>
              {!payment.includes(EnumPaymentMethodParameter.xspp) ?
                <>
                  {payment.length === 0 || payment.includes(EnumPaymentMethodParameter.xcp) ?
                    <CustomPayment option={option} selectPaymentMethod={selectPaymentMethod}/> : ""
                  }
                </> : ""}
            </> : ""}

          </div>

          {!payment.includes(EnumPaymentMethodParameter.xspp) ?
            <>
              {(payment.length === 0 || !payment.includes(EnumPaymentMethodParameter.xpb)) && !payment.includes(EnumPaymentMethodParameter.xspp) ?
                <div className={openBoleto ? styles.hideOtherInformation : styles.showOtherInformation}>
                  <button className={styles.btnOtherInformation} onClick={() => setOpenBoleto(!openBoleto)}>
                    + outras opções
                  </button>
                </div> : ""
              }
            </> : ""}

        </div>

        <div className={styles.cartResume}>
          <div className={styles.boxTable}>
            <div className={styles.title}>
              <p className={styles.product}>PRODUTO</p>
              <p className={styles.qtd}>QTD</p>
              <p className={styles.value} onClick={() => setShowPixParcelado("true")}>VALOR</p>
            </div>
            <table>
              <tbody>
              {cart && cart.items?.map((product) => (
                <React.Fragment key={product.sku}>
                  <tr>
                    <td className={styles.product}>
                      {product.name}
                    </td>
                    <td className={styles.qtd}>
                      {formatFloatToDecimal(product.qty)}
                    </td>
                    <td className={styles.value}>
                      {Number(product.discount) > 0 ?
                        <div className={styles.priceGroup}>
                          <p
                            className={styles.realPrice}>de <span>{formatMoneyMask((Number(product.price) * Number(product.qty)).toFixed(2))}</span> por
                          </p>
                          <p className={styles.newPrice}>
                            
                            {formatMoneyMask(((Number(product.price) - Number(product.discount)) * Number(product.qty)).toFixed(2))}
                          </p>
                        </div>
                        :
                        <p className={styles.newPrice}>
                          {formatMoneyMask((Number(product.price) * Number(product.qty)).toFixed(2))}
                        </p>
                      }
                    </td>
                  </tr>
                </React.Fragment>
              ))}
              </tbody>
            </table>

            {!isOnlyDigitalProduct ?
              <div className={styles.shipping}>
                <div className={styles.text}>
                  <p className={styles.send}>Envio</p>
                  <div className={styles.address}>
                    {address ? <p>{address.address}</p> : null}
                    <span onClick={handleChangeAddress}>
                      (Escolher Outro)
                    </span>
                  </div>
                </div>
                {cart && cart.shipping && cart.shipping.id && cart.shipping.price ?
                  <p className={styles.value}>
                    <span className={cart.shipping.discount ? styles.linethrough : ''}>
                      {formatMoneyMask((cart.shipping.price).toString()) + ' '}
                    </span>
                    {cart.shipping.discount ?
                      <span>
                         {formatMoneyMask((cart.shipping.price - cart.shipping.discount).toString())}
                      </span> : ''}
                  </p>
                  : null}
              </div> : null}

            {cart ? <>
              {totalDiscount > 0 &&
                <div className={styles.total + " " + styles.discount}>
                  <p>Desconto</p>
                  <p className={styles.value}>
                    - {formatMoneyMask(totalDiscount.toString())}
                  </p>
                </div>
              }
              {cart.discount_amount_coupon && Number(cart.discount_amount_coupon) > 0 ?
                <div className={styles.total + " " + styles.discount}>
                  <p>
                    {cart.coupon ? <>
                      Cupom ({cart.coupon})
                      <button onClick={() => removeCupom()}>Remover</button>
                    </> : <> Desconto Cupom</>}
                  </p>
                  <p className={styles.value}>
                    - {formatMoneyMask(cart.discount_amount_coupon?.toString())}
                  </p>
                </div> : ""}
              {cart.discount_amount_payment && Number(cart.discount_amount_payment) > 0 ?
                <div className={styles.total + " " + styles.discount}>
                  <p>Desconto (PIX)</p>
                  <p className={styles.value}>
                    - {formatMoneyMask(cart.discount_amount_payment?.toString())}
                  </p>
                </div> : ""}
              {cart.discount_amount_customer && Number(cart.discount_amount_customer) > 0 ?
                <div className={styles.total + " " + styles.discount}>
                  <p>Desconto Cliente</p>
                  <p className={styles.value}>
                    - {formatMoneyMask(cart.discount_amount_customer?.toString())}
                  </p>
                </div> : ""}
              {cart.discount_amount_checkout && Number(cart.discount_amount_checkout) > 0 ?
                <div className={styles.total + " " + styles.discount}>
                  <p>Desconto Checkout</p>
                  <p className={styles.value}>
                    - {formatMoneyMask(cart.discount_amount_checkout?.toString())}
                  </p>
                </div> : ""}
              {cart.discount_amount_cashback && Number(cart.discount_amount_cashback) > 0 ?
                <div className={styles.total + " " + styles.discount}>
                  <p>Desconto Cashback</p>
                  <p className={styles.value}>
                    - {formatMoneyMask((cart.discount_amount_cashback + cart.shipping_discount_cashback).toString())}
                  </p>
                </div> : ""}

              <div className={styles.total}>
                <p>Total</p>
                <p className={styles.value}>
                  <strong>{formatMoneyMask(cart.total_value?.toString())}</strong>
                </p>
              </div>
            </> : null}
            {checkoutDiscount ?
              <div className={styles.showLightningDiscount}>
                <div className={styles.divImageThunder}>
                  <img src={thunder} alt={"thunder"}/>
                  <label>DESCONTO RELÂMPAGO ATIVADO</label>
                </div>
                <div className={styles.divPriceDiscount}>
                  {!expiredCheckoutDiscount ?
                    <p>Você vai pagar <span>R$</span> {formatMask(cart.total_value?.toString())}, se comprar nos
                      próximos <Timer maxRange={300}
                                      setExpiredCheckoutDiscount={setExpiredCheckoutDiscount}
                                      setCart={(cart: Cart) => setCart(cart)}/>
                    </p> : <p>Desconto expirado</p>
                  }
                </div>
                {!expiredCheckoutDiscount ?
                  <p className={styles.differenceDiscount}>Uma economia de {percentageCheckoutDiscount}% sobre os
                    produtos</p> : ""
                }
              </div> : ""
            }
            {cart.items && <DiscountLightning className={"payment"}
                                              products={cart.items}
                                              reload={() => listProducts()}
                                              totalValue={cart.total_value - (cart.shipping_price ? parseFloat(cart.shipping_price) : 0)}
                                              discount={parseFloat(cart.discount_amount_campaign)}/>}
          </div>
          {cart.cashback_amount > 0 ? <div className={styles.cashback}>
            {cart.apply_cashback ? <p>Pontos aplicado</p> : <p>Você possui {cart.cashback_amount_points} pontos
              (R$ {formatMask(cart.cashback_amount.toString())})</p>}
            <button disabled={isLoadingCashback} onClick={() => applyCashback(!cart.apply_cashback)}
                    type="button">{cart.apply_cashback ? 'Remover' : 'Aplicar'}</button>
          </div> : ''}
          <div className={styles.cupomContent}>
            <button onClick={() => setOpenCupom(!openCupom)} className={styles.cupomBtn}>Você possui um cupom? <img
              className={openCupom ? styles.rotateImg : styles.arrowCupom} alt={"arrow-buttom"} src={arrowBottom}/>
            </button>
            {openCupom ?
              <div className={styles.cupom}>
                <label>Cupom</label>
                <div className={styles.cupomFormGroup}>
                  <input type="text" onChange={(event) => setCupom(event.target.value)} value={cupom}/>
                  <button disabled={isLoadingCupom} onClick={() => applyCupom()} type="button">Aplicar</button>
                </div>
              </div> : ""}
          </div>

          <button
            data-payment={option}
            disabled={isLoading || isLoadingCupom || isLoadingPayment}
            className={styles.buttonFinish + " btn-payment"}
            onClick={() => {
              if (option === 'pagarme_cc' || option === 'mercadopago_cc') {
                if (formRef.current) {
                  formRef.current.dispatchEvent(
                    new Event('submit', {cancelable: true, bubbles: true})
                  )
                }
              } else {
                onSubmit({})
              }
            }}
          >
            Finalizar Compra
          </button>
        </div>
      </div>
      <ModalPagaleve modalOpen={openModalPagaleve} closeModal={() => setOpenModalpagaleve(!openModalPagaleve)}
                     urlIframe={urlIframePagaleve}/>

    </>
  )
}
