import {ReactComponent as LessIcon} from '../../assets/icons/less.svg';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import {ReactComponent as TrashIcon} from '../../assets/icons/trash.svg';

import {Product, ProductLoading} from '../../ts/interfaces/Product';
import {formatMoneyMask, formatFloatToDecimal} from '../../helper/FormatFields';

import './styles.scss';
import {useState} from 'react';
import Loading from '../Loading';

interface ProductsProps {
  data: Product;
  removingProduct: ProductLoading;
  isLoadingQuantity: ProductLoading;
  onChangeLessProduct: (
    sku: string, setQuantity: React.Dispatch<React.SetStateAction<string | number>>
  ) => void;
  onChangePlusProduct: (
    sku: string, setQuantity: React.Dispatch<React.SetStateAction<string | number>>
  ) => void;
  onRemoveProduct: (id: string) => void;
}

/**
 *
 * @param data -> Cart object
 * @params onRemoveProduct
 * @params onChangePlusProduct
 * @params onChangeLessProduct
 * @returns List of products in cart
 */
export default function CartItem({
                                   data,
                                   removingProduct,
                                   isLoadingQuantity,
                                   onRemoveProduct,
                                   onChangePlusProduct,
                                   onChangeLessProduct
                                 }: ProductsProps) {
  const [quantity, setQuantity] = useState<string | number>(data.qty);

  function applyDiscount() {
    return formatMoneyMask(
      (Number(data.price) - Number(data.discount)).toFixed(2)
    );
  }

  function percentDiscount() {
    let finalValue = Number(data.price) - Number(data.discount);
    let x = (finalValue * 100) / Number(data.price);
    return Math.floor(Math.abs(100 - x));
  }

  return (
    <div className='cart-item-container'>
      <div className='cart-item-product'>
        <img className='product-image' src={data.path_image} alt="product-img"/>
        <p><span>{data.name}</span><small>SKU: {data.sku}</small></p>
      </div>

      <div className='cart-item-quantity-value'>
        {removingProduct.isLoading && removingProduct.sku === data.sku ? (
          <div className='cart-item-quantity center'>
            <Loading size='BIG'/>
          </div>
        ) : (
          <div className={`cart-item-quantity ${data.is_shipping && 'center'}`}>
            {/* icone da lixeira somente no mobile */}
            <TrashIcon data-sku={data.sku} data-name={data.name} data-price={(data.price - data.discount).toFixed(2)} data-qty={data.qty}
                       className="trash-icon mobile btn_remove"
                       onClick={() => onRemoveProduct(data.sku)}/>
            {data.is_shipping ?
              <div className='add-or-remove'>
                {isLoadingQuantity.isLoading && isLoadingQuantity.sku === data.sku ? (
                  <Loading size='MEDIUM'/>
                ) : (
                  <>
                    <LessIcon
                      data-sku={data.sku}
                      data-name={data.name}
                      data-price={(data.price - data.discount).toFixed(2)}
                      data-qty={data.qty}
                      className="btn_remove"
                      onClick={() => onChangeLessProduct(data.sku, setQuantity)}
                    />
                    <small>{formatFloatToDecimal(quantity.toString())}</small>
                    <PlusIcon
                      data-sku={data.sku}
                      data-name={data.name}
                      data-price={(data.price - data.discount).toFixed(2)}
                      data-qty={data.qty}
                      className="btn_add"
                      onClick={() => onChangePlusProduct(data.sku, setQuantity)}
                    />
                  </>
                )}
              </div>
              : ""}
            <span
              data-sku={data.sku}
              data-name={data.name}
              data-price={(data.price - data.discount).toFixed(2)}
              data-qty={data.qty}
              className='remove-text btn_exclude'
              onClick={() => onRemoveProduct(data.sku)}
            >
              Excluir Item
            </span>
          </div>
        )}


        <div className='cart-item-value'>
          <div className='cart-item-content'>
            {Number(data.discount) > 0 ? (
              <>
                <span className='price-without-discount'>
                  de {formatMoneyMask(data.price.toString())}
                </span>
                <div>
                  <span className='total-price'>{applyDiscount()}</span>
                  <span className='discount-percent'>
                    (-{percentDiscount()}%)
                  </span>
                </div>
              </>
            ) : (
              <span className='total-price'>{formatMoneyMask(data.price.toString())}</span>
            )}
            {data.price_changed && data.message ? <p className="price-before">({data.message})</p> : ""}
          </div>
        </div>
      </div>
    </div>
  )
}
