import {formatMoneyMask} from '../../../helper/FormatFields';
import {Order} from "../../../ts/interfaces/Order";

import './styles.scss';
import React from "react";
import styles from "../styles.module.scss";

interface PaymentResumeProps {
  order: Order;
}

export default function PaymentResume({order}: PaymentResumeProps) {

  function applyDiscount(price: number | string, discount: number | string, qty: number | string) {
    return formatMoneyMask(
      ((Number(price)) * Number(qty)).toFixed(2)
    );
  }

  return (
    <>
      <h2>Resumo da compra</h2>
      <div className='payment-resume-container'>
        <div className='payment-mode-resume'>
          {order.items.map((orderItem, index) => (
            <div className='product-content' key={orderItem.sku}>
              <div className='product-details'>
                <img src={orderItem.path_image} alt="product"/>
                <div className='product-description'>
                  <span>{orderItem.name}</span>
                  <p>Código do produto: ({orderItem.sku}) <br/>Quantidade: {orderItem.qty}</p>
                </div>
              </div>
              <div className='product-value'>
                {index === 0 && (
                  <p>Valor</p>
                )}
                <span className={`price ${index > 0 && 'price-center'}`}>
                  {applyDiscount(orderItem.price, orderItem.discount_amount, orderItem.qty)}
                </span>
              </div>
            </div>
          ))}
          <div className='resume-prices'>
            <div>
              <span>Subtotal:</span>
              <span className='price'>
                {formatMoneyMask(order.sub_total.toString())}
              </span>
            </div>
            <div>
              <span>Frete:</span>
              {order.shipping_price ? (
                <span className='price'>
                  {formatMoneyMask(order.shipping_price.toString())}
                </span>
              ) : (
                <span>--</span>
              )}
            </div>
            {Number(order.discount_amount) > 0 ? <div>
              <span>Desconto:</span>
              <span className='price'>
                - {formatMoneyMask(order.discount_amount.toString())}
              </span>
            </div> : <></>}
            {Number(order.discount_amount_cashback) > 0 ? <div>
              <span>Cashback aplicado:</span>
              <span className='price'>
                - {formatMoneyMask(order.discount_amount_cashback.toString())}
              </span>
            </div> : <></>}
            {Number(order.discount_amount_coupon) > 0 ? <div>
              <span>Cupom ({order.coupon}):</span>
              <span className='price'>
                - {formatMoneyMask(order.discount_amount_coupon.toString())}
              </span>
            </div> : <></>}
            {Number(order.discount_amount_campaign) > 0 ? <div>
              <span>Desconto:</span>
              <span className='price'>
                - {formatMoneyMask(order.discount_amount_campaign.toString())}
              </span>
            </div> : <></>}
            <div className='divisor'/>
            <div>
              <span>TOTAL:</span>
              <div className='total-price'>
                <span className='price'>
                  {formatMoneyMask(order.grand_total.toString())}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
