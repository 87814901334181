/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Cookies} from 'react-cookie';
import {toast} from "react-toastify";

import Header from '../../component/Header/header';
import CartItems from '../../component/CartItems';
import CartResume from '../../component/CartResume';
import Preloader from '../../component/Preloader';

import {
  calculateShipping,
  decrementProduct,
  incrementProduct,
  cartAddItem,
  getCart,
  removeProduct,
  selectShippingService
} from '../../services/services';

import {Product, ProductLoading} from '../../ts/interfaces/Product';
import {ShippingService} from '../../ts/interfaces/Shipping';
import {Cart} from '../../ts/interfaces/Cart';

import useLocalStorage from '../../hooks/useLocalStorage'

import './styles.scss';
import TagManager from "react-gtm-module";
import hasEvent from "../../helper/dataLayer";
import DiscountLightning from "../DiscountLightning/DiscountLightning";


interface CartProps {
  products: Product[];
  totalValue: string;
  shipping: ShippingService;
  cart: Cart;
}

export default function CartPage() {

  document.title = 'Carrinho de compras';

  const navigate = useNavigate();
  const {search} = useLocation();
  const listProductIsRendered = useRef(false);
  const cookies = new Cookies();

  const [cartObj, setCartObj] = useState<CartProps>({
    products: [],
    totalValue: '',
    shipping: {} as ShippingService,
    cart: {} as Cart,
  });
  const [, setShippingSelected] = useState(cartObj.shipping || '');
  const [shippingServicesList, setShippingServicesList] = useState<ShippingService[]>([]);
  const [storagedPostalCode, setStoragedPostalCode] = useLocalStorage(
    '@postal-code', ''
  );
  const [postalCode, setPostalCode] = useState(storagedPostalCode || '');
  const [isLoadingProducts, setLoadingProducts] = useState(false);
  const [removingProduct, setRemovingProduct] = useState({} as ProductLoading);
  const [isLoadingQuantity, setLoadingQuantity] = useState({} as ProductLoading);
  const [isLoadingResume, setLoadingResume] = useState(false);
  const [sentMsgChangePrice, setSentMsgChangePrice] = useState(false);

  useEffect(() => {
    if (cartObj && cartObj.cart && Object.keys(cartObj.cart).length > 0) {
      if (!hasEvent('ee_cart')) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'ee_cart',
            cartId: cartObj.cart.session_id,
            externalCheckoutId: cartObj.cart.session_id,
            freight_payable: cartObj.shipping ? 1 : -1,
            products: cartObj.cart.items.map((item) => {
              return {
                brand: "Nova Concursos",
                category: item.type + "/" + item.name,
                id: item.sku,
                image: item.path_image,
                name: item.name,
                price: item.price - item.discount,
                quantity: item.qty,
                variant: item.type
              }
            }),
            product_ids: cartObj.cart.items.map((item) => {
              return item.sku
            }),
            totalFreightCart: cartObj.cart.shipping_price,
            totalValue: cartObj.cart.total_value,
            user_id: cartObj.cart.customer_email_md5,
            em: cartObj.cart.customer_email_hash256,
            ph: cartObj.cart.customer_ph_hash256,
            fn: cartObj.cart.customer_fn_hash256,
            ln: cartObj.cart.customer_ln_hash256,
            customer_email: cartObj.cart.customer_email,
            customer_ph: cartObj.cart.customer_ph,
            customer_fn: cartObj.cart.customer_fn,
            customer_ln: cartObj.cart.customer_ln
          }
        });
      }
      if (!sentMsgChangePrice && cartObj && cartObj.cart && cartObj.cart.items && cartObj.cart.items.some((item) => item.price_changed)) {
        toast.info("Houve alteração de valor em um ou mais produtos do seu carrinho.");
        setSentMsgChangePrice(true);
      }
    }
  }, [cartObj]);

  useEffect(() => {
    let params = new URLSearchParams(search);
    sessionStorage.setItem('payment_method', params.get('ind_pag') ?? '');
  }, [])

  function setCartInformation(cart: Cart) {
    setCartObj({
      products: cart.items,
      totalValue: cart.total_value.toString(),
      shipping: cart.shipping,
      cart: cart
    });

    let date = new Date();
    date.setDate(date.getDate() + 7);
    let options = {
      expires: date,
      domain: process.env.REACT_APP_DOMAIN_COOKIE,
      secure: true,
      httpOnly: false,
      path: "/"
    };
    cookies.set('frontendcart', JSON.stringify({total: cart.items.length, name: cart.customer_name}), options);
  }

  const findProductsByHash = useCallback(async (cartHash: string, coupon: string | null) => {
    try {
      setLoadingProducts(true);

      const {data} = await cartAddItem(cartHash, coupon);

      if (data.success) {
        const cart = data.data;
        setCartInformation(cart);

        if (cart.shipping?.cep) {
          setPostalCode(cart.shipping?.cep);
        }


      }
    } catch (error) {
      console.log('findProductsByHash::ERROR', error);
    } finally {
      setLoadingProducts(false);
    }
  }, []);

  const findProductsByCreatedCookie = useCallback(async () => {
    try {
      setLoadingProducts(true);

      const {data} = await getCart();

      if (data.success && data.data) {
        const cart = data.data;

        setCartInformation(cart);
        if (cart.shipping?.cep) {
          setPostalCode(cart.shipping?.cep);
        }
      }
    } catch (error) {
      console.log('findProductsByCreatedCookie::ERROR', error)
    } finally {
      setLoadingProducts(false);
    }
  }, []);

  const listProducts = useCallback(async () => {
    const cartHash = new URLSearchParams(search).get('t');
    const coupon = new URLSearchParams(search).get('cupom');
    let forward = new URLSearchParams(search).get('forward');

    if (cartHash) {
      findProductsByHash(cartHash, coupon);
      let queryParams = search.replaceAll("?", "").split("&").map((item) => {
        return item.split("=");
      }).reduce(function (acc, cur, i) {
        // @ts-ignore
        acc[cur[0]] = cur[1];
        return acc;
      }, {});
      // @ts-ignore
      delete queryParams['t'];
      // @ts-ignore
      delete queryParams['cupom'];
      // @ts-ignore
      delete queryParams[''];
      // @ts-ignore
      let queryParameters = new URLSearchParams(queryParams).toString();
      window.history.pushState('', '', '/' + (queryParameters ? "?" + queryParameters : ""))

      if (forward) {
        let params = {
          state: `cart${isOnlyDigitalProduct ? "Digital" : ""}`
        }
        if (forward === 'completar') {
          forward = forward + '?hiddenBump=true'
        }
        navigate('/' + forward, params)
      }
    } else {
      findProductsByCreatedCookie();
    }
  }, [findProductsByCreatedCookie, findProductsByHash, search, navigate]);

  const handleCalculateShipping = useCallback(async () => {
    try {
      setLoadingResume(true);

      if (postalCode) {
        const {data} = await calculateShipping(postalCode.replace("-", ""));

        if (data.success && data.data) {
          setCartInformation(data.data.cart);
          setShippingServicesList(data.data.services);
          setStoragedPostalCode(postalCode.replace("-", "")); // save in storage after calculating the zip code
        }
      }
    } catch (error) {
      console.log('CALCULATE_SHIPPING::ERROR', error);
    } finally {
      setLoadingResume(false);
    }
  }, [cartObj.shipping, postalCode, setStoragedPostalCode]);

  const handleSelectShippingService = useCallback(async (item: ShippingService) => {
    setLoadingResume(true);

    const payload = {
      ...item,
      cep: postalCode.replace("-", ""),
    };

    try {
      const {data} = await selectShippingService(payload);

      if (data.success) {
        setShippingSelected(data.data.cart.shipping);
        setShippingServicesList(data.data.services);
        setCartInformation(data.data.cart);
      }
    } catch (error) {
      console.log('SHIPPING::ERROR', error);
    } finally {
      setLoadingResume(false);
    }

  }, [postalCode]);

  const handleRemoveProduct = useCallback(async (sku: string) => {
    setRemovingProduct({
      isLoading: true,
      sku,
    });

    setLoadingResume(true);

    await removeProduct(sku).then(({data}) => {
      const cart = data.data;
      setCartInformation(cart);
      handleCalculateShipping();
    }).finally(() => {
      setRemovingProduct({
        isLoading: false,
        sku: '',
      });

      if (!cartObj.shipping) {
        setLoadingResume(false);
      }
    });
  }, [handleCalculateShipping]);

  const handleLessProduct = useCallback(async (
    sku: string,
    setQuantity: React.Dispatch<React.SetStateAction<string | number>>
  ) => {
    try {
      setQuantity(prevState => Number(prevState) - 1);

      setLoadingQuantity({
        isLoading: true,
        sku,
      });

      setLoadingResume(true);

      await decrementProduct(sku).then(({data}) => {
        const cart = data.data;
        setCartInformation(cart);
        handleCalculateShipping();
      });

    } catch {
      setQuantity(prevState => Number(prevState) + 1);
    } finally {
      setLoadingQuantity({
        isLoading: false,
        sku: '',
      });

      if (!cartObj.shipping) {
        setLoadingResume(false);
      }
    }
  }, [handleCalculateShipping]);

  const handlePlusProduct = useCallback(async (
    sku: string,
    setQuantity: React.Dispatch<React.SetStateAction<string | number>>
  ) => {
    try {
      setQuantity(prevState => Number(prevState) + 1);

      setLoadingQuantity({
        isLoading: true,
        sku,
      });

      setLoadingResume(true);

      await incrementProduct(sku).then(({data}) => {
        const cart = data.data;
        setCartInformation(cart);
        handleCalculateShipping();
      });

    } catch {
      setQuantity(prevState => Number(prevState) - 1);
    } finally {
      setLoadingQuantity({
        isLoading: false,
        sku: '',
      });

      if (!cartObj.shipping) {
        setLoadingResume(false);
      }
    }

  }, [handleCalculateShipping]);

  const isOnlyDigitalProduct = useMemo(() => {
    return cartObj.products?.every((item) => !item.is_shipping)
  }, [cartObj.products]);

  useEffect(() => {
    if (!listProductIsRendered.current) {
      listProducts();
      listProductIsRendered.current = true; // remove re-render and make render once time
    }
  }, [listProducts]);

  useEffect(() => {
    if (!isOnlyDigitalProduct) {
      handleCalculateShipping();
    }
  }, [isOnlyDigitalProduct]);

  return (
    <>
      <Preloader isVisible={isLoadingProducts}/>
      <Header/>
      {cartObj.products && cartObj.products.length > 0 && <DiscountLightning className={"cart"}
                                                                             products={cartObj.products}
                                                                             reload={() => findProductsByCreatedCookie()}
                                                                             totalValue={cartObj.cart.total_value}
                                                                             discount={parseFloat(cartObj.cart.discount_amount)}/>}
      <div className="cart-container">
        {!isLoadingProducts && (
          <>
            <h1>{cartObj.products.length === 0 ? (
              'Seu carrinho está vazio'
            ) : (
              `Carrinho de Compras (${cartObj.products.length})`
            )}
            </h1>

            {cartObj.products.length > 0 && (
              <div className='cart-content'>
                <div className='cart-list'>
                  <div className='cart-list__header'>
                    <span className='items'>Item(s)</span>
                    <span>Quantidade</span>
                    <span>Valor Unitário</span>
                  </div>

                  {cartObj.products?.map((item) => (
                    <CartItems
                      key={item.sku}
                      data={item}
                      removingProduct={removingProduct}
                      isLoadingQuantity={isLoadingQuantity}
                      onChangeLessProduct={handleLessProduct}
                      onChangePlusProduct={handlePlusProduct}
                      onRemoveProduct={(sku) => handleRemoveProduct(sku)}
                    />
                  ))}
                </div>

                <div className='cart-resume'>
                  <a
                    href={process.env.REACT_APP_URL_HOME}
                    className='keep-buying'
                  >
                    Continuar comprando
                  </a>
                  <CartResume
                    isLoadingResume={isLoadingResume}
                    productsQuantity={cartObj.products.length}
                    cart={cartObj.cart}
                    isOnlyDigitalProduct={isOnlyDigitalProduct}
                    shippingServicesList={shippingServicesList}
                    onSelectShippingService={handleSelectShippingService}
                    onCalculateShipping={handleCalculateShipping}
                    onChangePostalCode={(postalCode) => setPostalCode(postalCode)}
                    postalCodeSaved={postalCode}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
